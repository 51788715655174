<template>
  <v-card
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    :class="{ 'grey lighten-2': dragover }"
    height="3.5em"
  >
    <input
      @change="onFileChange"
      type="file"
      ref="file"
      multiple
      style="display: none"
    />
    <v-hover v-slot:default="{ hover }">
      <v-card-text
        @click="$refs.file.click()"
        :class="{ 'on-hover elevation-4' : hover }"
        class="py-0 px-0"
        style="overflow: hidden;"
      >
        <v-row v-if="uploadedFiles.length === 0" class="d-flex flex-column" align="center"
               justify="center">
          <v-icon
            :class="[dragover ? 'mt-2 mb-3' : 'mt-4']"
          >
            mdi-cloud-upload
          </v-icon>
          <p>
            {{ text }}
          </p>
        </v-row>
        <v-virtual-scroll
          v-if="uploadedFiles.length > 0"
          :items="uploadedFiles"
          height="4.3em"
          item-height="1px"
          class="pt-0"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.name">
              <v-list-item-content class="px-0 py-0">
                <v-list-item-title>
                  {{ item.name }}
                  <span class="ml-1 text--secondary">
                    {{ item.size }} bytes</span
                  >
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn @click.stop="removeFile(item.name)" icon>
                  <v-icon> mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-card-text>
    </v-hover>
  </v-card>
</template>

<script>
export default {
  name: 'FileUploader',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    initialFiles: {
      required: false,
      type: Array,
      default() {
        return [];
      }
    },
    text: {
      type: String,
      required: false,
      default() {
        return "Drop your file(s) here, or click to select them.";
      }
    }
  },
  data() {
    return {
      dragover: false,
      uploadedFiles: []
    };
  },
  methods: {
    closeDialog() {
      // Remove all the uploaded files
      this.uploadedFiles = [];
    },
    removeFile(fileName) {
      // Find the index of the
      const index = this.uploadedFiles.findIndex(
        file => file.name === fileName
      );
      // If file is in uploaded files remove it
      if (index > -1) this.uploadedFiles.splice(index, 1);
    },
    onDrop(e) {
      this.dragover = false;

      // If there are already uploaded files remove them
      if (this.uploadedFiles.length > 0) this.uploadedFiles = [];

      // If user has uploaded multiple files but the component is not multiple throw error
      if (!this.multiple && e.dataTransfer.files.length > 1) {
        this.$store.dispatch('error', 'Only one file can be uploaded at a time...');
      }

      // Add each file to the array of uploaded files
      else {
        e.dataTransfer.files.forEach(element =>
          this.uploadedFiles.push(element)
        );
      }
    },
    onFileChange(event) {
      this.dragover = false;
      const files = event.target.files;

      if (files.length === 0) {
        return;
      }

      // If user has uploaded multiple files but the component is not multiple throw error
      if (!this.multiple) {
        if (files.length > 1) {
          this.$store.dispatch('error', 'Only one file can be uploaded at a time...');
        }

        // if not multiple enabled, only use one file
        this.uploadedFiles = [files[0]];
      }
      // Add each file to the array of uploaded files
      else {
        files.forEach(element =>
          this.uploadedFiles.push(element)
        );
      }
    },
    submit() {
      // If there aren't any files to be uploaded throw error
      if (!this.uploadedFiles.length > 0) {
        this.$store.dispatch('error', 'There are no files to upload');
      } else {
        // Send uploaded files to parent component
        this.$emit('filesUploaded', this.uploadedFiles);
        // Close the dialog box
        this.closeDialog();
      }
    }
  },
  created() {
    this.uploadedFiles = this.initialFiles;
  }
};
</script>

<style>
.on-hover {
  cursor: pointer;
}
</style>
