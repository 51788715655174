var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "150px",
      "height": "70px"
    }
  }, [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "100",
      "max-height": "200",
      "type": "text"
    }
  }), _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "160",
      "max-height": "200",
      "type": "text"
    }
  }), _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "140",
      "max-height": "200",
      "type": "text"
    }
  }), _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "150",
      "max-height": "200",
      "type": "text"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }