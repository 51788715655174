var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "statistic-container"
  }, [_c('v-row', {
    staticClass: "statistic-header"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "2"
    }
  }, [_c('date-time-picker', {
    attrs: {
      "textFieldProps": _vm.textFieldProps,
      "label": 'From Date (GMT+' + _vm.getGmtDiff + ')'
    },
    on: {
      "input": _vm.initStatistics
    },
    model: {
      value: _vm.fromDate,
      callback: function ($$v) {
        _vm.fromDate = $$v;
      },
      expression: "fromDate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "2"
    }
  }, [_c('date-time-picker', {
    attrs: {
      "textFieldProps": _vm.textFieldProps,
      "label": 'Until Date (GMT+' + _vm.getGmtDiff + ')'
    },
    on: {
      "input": _vm.initStatistics
    },
    model: {
      value: _vm.untilDate,
      callback: function ($$v) {
        _vm.untilDate = $$v;
      },
      expression: "untilDate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "2"
    }
  }, [_c('v-select', {
    staticClass: "font-weight-bold",
    attrs: {
      "outlined": "",
      "items": _vm.tenants,
      "item-text": "name",
      "item-value": "id",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Select tenants",
      "multiple": "",
      "single-line": "",
      "hide-details": "",
      "chips": ""
    },
    on: {
      "change": _vm.initStatistics
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [index < 1 ? _c('v-chip', [_c('span', [_vm._v(_vm._s(item.name))])]) : _vm._e(), index === 1 ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_vm._v(" (+" + _vm._s(_vm.selectedValues.length - 1) + ") ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selectedValues,
      callback: function ($$v) {
        _vm.selectedValues = $$v;
      },
      expression: "selectedValues"
    }
  })], 1), _c('v-col', {
    staticClass: "mx-auto",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "3"
    }
  }, [_c('file-uploader-mini', {
    ref: "fileUploader",
    attrs: {
      "multiple": false,
      "text": 'Upload an external report to match (Optional)'
    }
  })], 1), _c('v-col', {
    staticClass: "mx-auto",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "3"
    }
  }, [_c('v-btn', {
    staticClass: "button greeny text-button",
    staticStyle: {
      "height": "56px",
      "width": "100%"
    },
    attrs: {
      "dark": "",
      "hint": "Download Accounting Report"
    },
    on: {
      "click": function ($event) {
        return _vm.downloadAccountingReport();
      }
    }
  }, [_c('v-icon', {
    staticStyle: {
      "margin-right": "5px"
    }
  }, [_vm._v(" mdi-microsoft-excel ")]), _vm._v(" Download Report ")], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12"
    }
  }, [_c('label', {
    attrs: {
      "for": "select"
    }
  }, [_vm._v("Filter by Claim Events:")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.event.status,
      expression: "event.status"
    }],
    staticClass: "pl-2 py-1 mb-3 my-select",
    class: {
      'my-select-dark': _vm.$vuetify.theme.dark
    },
    attrs: {
      "id": "select"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.event, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.eventStatus, function (item, i) {
    return _c('optgroup', {
      key: i,
      attrs: {
        "label": item.text
      }
    }, _vm._l(item.children, function (opt) {
      return _c('option', {
        key: opt.code,
        domProps: {
          "value": opt.code
        }
      }, [_vm._v(" " + _vm._s(opt.text) + " ")]);
    }), 0);
  }), 0)])], 1), _c('div', {
    staticClass: "statistic-information d-flex"
  }, [_c('v-row', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._l(_vm.statistics.statisticsPerCurrency, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "lg": "3"
      }
    }, [_c('div', {
      staticClass: "statistic-item"
    }, [_c('div', {
      staticClass: "content"
    }, [_vm.loading ? _c('ListItemLoader') : [_c('h2', [_vm._v(_vm._s(item.currency))]), _c('div', {
      staticClass: "mt-1 mb-1"
    }, [_c('v-divider')], 1), _c('table', {
      staticStyle: {
        "vertical-align": "top",
        "width": "100%"
      }
    }, [_c('tr', [_c('td', {
      staticStyle: {
        "padding-top": "10px"
      },
      attrs: {
        "colspan": "2"
      }
    }, [_c('table', {
      staticStyle: {
        "border-collapse": "collapse",
        "border-spacing": "0",
        "margin": "0",
        "width": "100%"
      },
      attrs: {
        "role": "presentation"
      }
    }, [_c('tbody', [_c('tr', [_c('td', {
      staticStyle: {
        "text-align": "left",
        "font-size": "13px"
      }
    }, [_vm._v(" Sold Gift Cards ")]), _c('td', [_vm._v(":")]), _c('td', {
      staticStyle: {
        "text-align": "right",
        "font-size": "13px",
        "width": "34%"
      }
    }, [_vm._v(" " + _vm._s(item.giftCardCount) + " ")])]), _c('tr', [_c('td', {
      staticStyle: {
        "text-align": "left",
        "font-size": "13px"
      }
    }, [_vm._v(" Sold Railpass ")]), _c('td', [_vm._v(":")]), _c('td', {
      staticStyle: {
        "text-align": "right",
        "font-size": "13px"
      }
    }, [_vm._v(" " + _vm._s(item.railPassCount) + " ")])]), _c('tr', [_c('td', {
      staticStyle: {
        "text-align": "left",
        "font-size": "13px"
      }
    }, [_vm._v(" Sold Articles ")]), _c('td', [_vm._v(":")]), _c('td', {
      staticStyle: {
        "text-align": "right",
        "font-size": "13px"
      }
    }, [_vm._v(" " + _vm._s(item.articleCount) + " ")])]), _c('tr', [_c('td', {
      staticStyle: {
        "text-align": "left",
        "font-size": "13px"
      }
    }, [_vm._v(" Sold Orders "), _c('div', {
      staticClass: "mt-2 mb-1"
    }, [_c('v-divider')], 1)]), _c('td', [_vm._v(":")]), _c('td', {
      staticStyle: {
        "text-align": "right",
        "font-size": "13px"
      }
    }, [_vm._v(" " + _vm._s(item.orderCount) + " "), _c('div', {
      staticClass: "mt-2 mb-1"
    }, [_c('v-divider')], 1)])]), _c('tr', [_c('td', {
      staticStyle: {
        "text-align": "left",
        "font-size": "13px",
        "display": "inline-block",
        "white-space": "nowrap",
        "font-weight": "bold"
      }
    }, [_vm._v(" Amount Average ")]), _c('td', [_vm._v(":")]), _c('td', {
      staticStyle: {
        "font-size": "13px",
        "text-align": "right",
        "font-weight": "bold"
      }
    }, [_vm._v(" " + _vm._s(item.averageValue.plainText) + " ")])]), _c('tr', [_c('td', {
      staticStyle: {
        "text-align": "left",
        "font-size": "13px",
        "font-weight": "bold"
      }
    }, [_vm._v(" Amount Total ")]), _c('td', [_vm._v(":")]), _c('td', {
      staticStyle: {
        "font-size": "13px",
        "text-align": "right",
        "display": "inline-block",
        "white-space": "nowrap",
        "font-weight": "bold"
      }
    }, [_vm._v("   " + _vm._s(item.totalValue.plainText) + " ")])])])])])])])]], 2)])]);
  }), _c('v-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "statistic-item"
  }, [_c('div', {
    staticClass: "content"
  }, [_vm.loading ? _c('ListItemLoader') : [_c('h2', [_vm._v("TOTAL")]), _c('div', {
    staticClass: "mt-1 mb-1"
  }, [_c('v-divider')], 1), _c('table', {
    staticStyle: {
      "vertical-align": "top",
      "width": "100%"
    }
  }, [_c('tr', [_c('td', {
    staticStyle: {
      "padding-top": "10px"
    },
    attrs: {
      "colspan": "2"
    }
  }, [_c('table', {
    staticStyle: {
      "border-collapse": "collapse",
      "border-spacing": "0",
      "margin": "0",
      "width": "100%"
    },
    attrs: {
      "role": "presentation"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px"
    }
  }, [_vm._v(" Sold Gift Cards ")]), _c('td', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "width": "34%",
      "display": "inline-block",
      "white-space": "nowrap"
    }
  }, [_vm._v(" : " + _vm._s(_vm.statistics.statisticsTotal.giftCardCount) + " ")])]), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px"
    }
  }, [_vm._v(" Sold Railpass ")]), _c('td', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "display": "inline-block",
      "white-space": "nowrap"
    }
  }, [_vm._v(" : " + _vm._s(_vm.statistics.statisticsTotal.railpassCount) + " ")])]), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px"
    }
  }, [_vm._v(" Sold Articles ")]), _c('td', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "display": "inline-block",
      "white-space": "nowrap"
    }
  }, [_vm._v(" : " + _vm._s(_vm.statistics.statisticsTotal.articleCount) + " ")])]), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px"
    }
  }, [_vm._v(" Sold Orders ")]), _c('td', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "display": "inline-block",
      "white-space": "nowrap"
    }
  }, [_vm._v(" : " + _vm._s(_vm.statistics.statisticsTotal.orderCount) + " ")])])])])])])])]], 2)])])], 2)], 1)], 1), _c('v-card', {
    staticClass: "my-7 px-4 py-7"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('apexchart', {
    attrs: {
      "type": "area",
      "height": "350",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "my-7 px-4 py-7"
  }, [_c('div', {
    staticClass: "d-flex justify-end py-3"
  }, [_c('export-excel', {
    attrs: {
      "data": _vm.statistics.orders
    }
  }, [_c('v-btn', {
    attrs: {
      "elevation": "2",
      "color": "greeny",
      "dark": "",
      "small": ""
    }
  }, [_c('v-icon', [_vm._v(" mdi-microsoft-excel")])], 1)], 1), _c('export-excel', {
    attrs: {
      "data": _vm.statistics.orders,
      "type": "csv"
    }
  }, [_c('v-btn', {
    staticClass: "mx-3",
    attrs: {
      "elevation": "2",
      "dark": "",
      "color": "greeny",
      "small": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-table-large")])], 1)], 1)], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.statistics.orders,
      "loading": _vm.loading,
      "items-per-page": _vm.filter.itemsPerPage,
      "options": _vm.filter,
      "footer-props": {
        'items-per-page-options': [10, 20, 50, 100, 250, 500]
      }
    },
    on: {
      "update:options": function ($event) {
        _vm.filter = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: `item.priceTotal`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return undefined;
      }
    }], null, true)
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }