<template>
  <div>
    <v-card class="statistic-container">
      <v-row class="statistic-header">
        <v-col cols="12" sm="12" md="2">
          <date-time-picker
            @input="initStatistics"
            :textFieldProps="textFieldProps"
            :label="'From Date (GMT+' + getGmtDiff + ')'"
            v-model="fromDate"
          >
          </date-time-picker>
        </v-col>
        <v-col cols="12" sm="12" md="2">
          <date-time-picker
            @input="initStatistics"
            :textFieldProps="textFieldProps"
            :label="'Until Date (GMT+' + getGmtDiff + ')'"
            v-model="untilDate"
          >
          </date-time-picker>
        </v-col>
        <v-col cols="12" sm="12" md="2">
          <v-select
            outlined
            @change="initStatistics"
            v-model="selectedValues"
            :items="tenants"
            item-text="name"
            class="font-weight-bold"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="Select tenants"
            multiple
            single-line
            hide-details
            chips
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 1">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ selectedValues.length - 1 }})
              </span>
            </template>
          </v-select
          >
        </v-col>
        <v-col cols="12" sm="12" md="3" class="mx-auto">
          <file-uploader-mini :multiple="false"
                              :text="'Upload an external report to match (Optional)'"
                              ref="fileUploader"/>
        </v-col>
        <v-col cols="12" sm="12" md="3" class="mx-auto">
          <v-btn
            dark
            class="button greeny text-button"
            style="height:56px;width:100%"
            @click="downloadAccountingReport()"
            hint="Download Accounting Report"
          >
            <v-icon style="margin-right: 5px">
              mdi-microsoft-excel
            </v-icon>
            Download Report
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <label for="select">Filter by Claim Events:</label>
          <select
            id="select"
            v-model="event.status"
            :class="{ 'my-select-dark': $vuetify.theme.dark }"
            class="pl-2 py-1 mb-3 my-select"
          >
            <optgroup v-for="(item, i) in eventStatus" :key="i" :label="item.text">
              <option v-for="opt in item.children" :key="opt.code" :value="opt.code">
                {{ opt.text }}
              </option>
            </optgroup>
          </select>
        </v-col>
      </v-row>
      <div class="statistic-information d-flex">
        <v-row cols="12">
          <v-col lg="3" v-for="(item,index) in statistics.statisticsPerCurrency" :key="index">
            <div class="statistic-item">
              <div class="content">
                <ListItemLoader v-if="loading"/>
                <template v-else>
                  <h2>{{ item.currency }}</h2>
                  <div class="mt-1 mb-1">
                    <v-divider></v-divider>
                  </div>
                  <table style="vertical-align:top; width:100%;">
                    <tr>
                      <td colspan="2" style="padding-top:10px;">
                        <table role="presentation"
                               style="border-collapse:collapse;border-spacing:0; margin:0; width: 100%;">
                          <tbody>
                          <tr>
                            <td
                              style="text-align:left;font-size:13px; ">
                              Sold Gift Cards
                            </td>
                            <td>:</td>
                            <td
                              style="text-align: right; font-size:13px; width:34%;">
                              {{ item.giftCardCount }}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style="text-align:left;font-size:13px; ">
                              Sold Railpass
                            </td>
                            <td>:</td>
                            <td
                              style="text-align: right; font-size:13px; ">
                              {{ item.railPassCount }}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style="text-align:left;font-size:13px;  ">
                              Sold Articles
                            </td>
                            <td>:</td>
                            <td
                              style="text-align: right; font-size:13px;  ">
                              {{ item.articleCount }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="text-align:left;font-size:13px;  ">
                              Sold Orders
                              <div class="mt-2 mb-1">
                                <v-divider></v-divider>
                              </div>
                            </td>
                            <td>:</td>
                            <td
                              style="text-align: right; font-size:13px;  ">
                              {{ item.orderCount }}
                              <div class="mt-2 mb-1">
                                <v-divider></v-divider>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="text-align:left;font-size:13px;display: inline-block;white-space: nowrap; font-weight:bold;  ">
                              Amount Average
                            </td>
                            <td>:</td>
                            <td
                              style=" font-size:13px; text-align: right;font-weight:bold; ">
                              {{ item.averageValue.plainText }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="text-align:left;font-size:13px;font-weight:bold;">
                              Amount Total
                            </td>
                            <td>:</td>
                            <td
                              style=" font-size:13px; text-align: right;display: inline-block;white-space: nowrap; font-weight:bold;">
                              &numsp; {{ item.totalValue.plainText }}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>
                </template>
              </div>
            </div>
          </v-col>
          <v-col lg="3">
            <div class="statistic-item">
              <div class="content">
                <ListItemLoader v-if="loading"/>
                <template v-else>
                  <!-- TOTAL PRICE -->
                  <h2>TOTAL</h2>
                  <div class="mt-1 mb-1">
                    <v-divider></v-divider>
                  </div>

                  <table style="vertical-align:top; width:100%;">
                    <tr>
                      <td colspan="2" style="padding-top:10px;">
                        <table role="presentation"
                               style="border-collapse:collapse;border-spacing:0; margin:0; width: 100%;">
                          <tbody>
                          <tr>
                            <td
                              style="text-align:left;font-size:13px; ">
                              Sold Gift Cards
                            </td>
                            <td
                              style="text-align: right; font-size:13px; width:34%;display: inline-block;white-space: nowrap;">
                              : {{ statistics.statisticsTotal.giftCardCount }}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style="text-align:left;font-size:13px; ">
                              Sold Railpass
                            </td>
                            <td
                              style="text-align: right; font-size:13px; display: inline-block;white-space: nowrap;">
                              : {{ statistics.statisticsTotal.railpassCount }}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style="text-align:left;font-size:13px;  ">
                              Sold Articles
                            </td>
                            <td
                              style="text-align: right; font-size:13px; display: inline-block;white-space: nowrap; ">
                              : {{ statistics.statisticsTotal.articleCount }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="text-align:left;font-size:13px;  ">
                              Sold Orders

                            </td>
                            <td
                              style="text-align: right; font-size:13px;display: inline-block;white-space: nowrap;  ">
                              : {{ statistics.statisticsTotal.orderCount }}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>
                </template>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card class="my-7 px-4 py-7">
      <v-row>
        <v-col cols="12">
          <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
        </v-col>
        <v-col cols="6">
          <!--<apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>-->
        </v-col>
      </v-row>
    </v-card>
    <v-card class="my-7 px-4 py-7">
      <div class="d-flex justify-end py-3">
        <export-excel :data="statistics.orders">
          <v-btn elevation="2" color="greeny" dark small>
            <v-icon> mdi-microsoft-excel</v-icon>
          </v-btn
          >
        </export-excel>

        <export-excel :data="statistics.orders" type="csv">
          <v-btn elevation="2" dark color="greeny" small class="mx-3">
            <v-icon dark> mdi-table-large</v-icon>
          </v-btn>
        </export-excel>
      </div>
      <v-data-table
        :headers="headers"
        :items="statistics.orders"
        :loading="loading"
        :items-per-page="filter.itemsPerPage"
        :options.sync="filter"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 250, 500]
        }"
      >
        <!--<template v-slot:[`item.bookings`]="{ item }">
          <template v-for="(booking, i) in item.bookings">
            <div :key="booking.id">
              <h4 class="grey--text text--darken-4 minw100">
                <v-tooltip
                  top
                  :open-delay="10"
                  :close-delay="5"
                >
                  <template v-slot:activator="{on, attrs}">
                    <v-icon
                      color="mango-red"
                      @click="copyToClipboard(booking.externalOrderId)"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-content-copy
                    </v-icon>
                  </template>
                  <span>Copied</span>
                </v-tooltip>
                <span class="ml-1 font-weight-bold">{{ booking.externalOrderId }}</span>
              </h4>
            </div>

            <v-divider v-if="i + 1 !== item.bookings.length" :key="i"></v-divider>
          </template>
        </template>-->

        <!--<template v-slot:[`item.seller`]="{ item }">
          <span>{{ item.bookings[0].externalOrderId }}</span>
          <div class="text-truncate font-weight-medium" style="max-width: 120px">
            <span>{{ item.seller.user.name }}</span>
          </div>
        </template>-->

        <!--<template v-slot:[`item.tenant`]="{ item }">
          <div v-if="item.seller.tenant" style="max-width: 170px">
            <div class="text-truncate font-weight-medium" style="max-width: 120px">
              <span>{{ item.seller.tenant.name }}</span>
            </div>
          </div>
        </template>-->

        <template v-slot:[`item.priceTotal`]="{ item }">
          <!--<span class="font-weight-bold one-line">
            {{ item.price.plainText }}
          </span>
          <v-tooltip content-class="tooltip tickettooltip" top lazy>
          <template #activator="{ on }" style="width: 200px">
            <v-icon
              color="mango-red"
              style="font-size: 18px"
              :class="item.isPaid ? 'paid' : 'notpaid'"
              small
              v-on="on"
            >
            </v-icon>
          </template>
          <span>HAS CLAIM TICKET</span>
        </v-tooltip> -->
        </template>
        <!--<template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item)" small dark>
                {{ item.lastUsed ? "Opened" : "Not Opened" }}
          </v-chip>
        </template>-->
        <!--<template v-slot:[`item.isPaid`]="{ item }">
          <v-chip
            class="font-weight-bold cliporder"
            style="
              min-width: 60px;
              font-size: 12px;
              min-height: 27px;
              text-transform: capitalize;
            "
            :class="getColorStatus(item.status)"
            link
            x-small
          >
            {{ item.status.replace("_", " ") }}
          </v-chip>
        </template>-->
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import DateTimePicker from "../../components/common/DateTimePicker.vue";
import ListItemLoader from "./_partials/ListItemLoader.vue";
import {copyToClipboard} from "@/util/helpers";
import {eventStatus} from "@/globals";
import FileUploaderMini from "@/components/media/FileUploaderMini.vue";
import Trainplanet from "@/util/trainplanet.api";

export default {
  components: {
    FileUploaderMini,
    DateTimePicker,
    ListItemLoader
  },

  data: () => ({
    data: [],
    series: [
      {
        name: "prices",
        data: []
      }
    ],
    event: {
      loading: false,
      message: "",
      status: ""
    },
    eventStatus: eventStatus,
    headers: [
      {
        text: "Order Id",
        value: "id",
        sortable: false
      },
      {
        text: "Linkonline ID",
        value: "externalBookingNumbers",
        sortable: false
      },
      {
        text: "Created",
        value: "created"
      },
      // {
      //   text: "Expires",
      //   value: "expires",
      //   sortable: false,
      // },
      {
        text: "Sold by",
        value: "soldBy",
        sortable: false
      },
      {
        text: "Tenant",
        value: "tenant",
        sortable: false
      },

      // {
      //   text: "Items",
      //   value: "itemsCount",
      //   align: "end",
      //   sortable: false,
      // },
      // {
      //   text: "Balance",
      //   value: "priceBalance",
      //   align: "end",
      //   sortable: false
      // },
      //{
      //  text: "Refunded Amount",
      //  value: "refundAmount",
      //  align: "end",
      //  sortable: false
      //},
      {
        text: "Total Price",
        value: "price.plainText",
        align: "end",
        sortable: false
      },
      // {
      //text: "Status",
      // value: "isPaid",
      //align: "center",
      //sortable: false
      //}
    ],
    loading: false,
    filter: {
      itemsPerPage: 10
    },
    chartOptions: {
      chart: {
        height: 350,
        type: "area"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        type: "datetime",
        categories: []
      },
      tooltip: {
        x: {}
      }
    },
    textFieldProps: {
      outlined: true,
      "hide-details": true
    },
    fromDate: new Date(
      moment().utc()
        .startOf("day")
        .subtract(24, "hours")
        // adjust so that it matches Swedish timezone midnight (GMT+1 or GMT+2 in daylight savings)
        .subtract(moment().tz("Europe/Stockholm").utcOffset() / 60, "hours")
      // .subtract(moment().tz("Europe/Stockholm").isDST() ? 1 : 0, "hours")
    ),
    untilDate: new Date(
      moment().utc()
        .startOf("day")
        // adjust so that it matches Swedish timezone midnight (GMT+1 or GMT+2 in daylight savings)
        .subtract(moment().tz("Europe/Stockholm").utcOffset() / 60, "hour")
      // .subtract(moment().tz("Europe/Stockholm").isDST() ? 1 : 0, "hour")
    ),
    menu_2: false,
    selectedTenant: "",
    selectedValues: []
  }),
  watch: {
    tenants(val) {
      if (val.length > 0) {
        this.selectedValues = this.tenants.map(item => item.id);
      }
    },
    "event.status": function (val) {
      this.initStatistics();
    }
  },
  mounted() {
    this.selectedValues = this.tenants.map(item => item.id);
    this.initStatistics();
  },
  computed: {
    ...mapGetters({
      statistics: "statistics/statistics",
      user: "auth/user",
      tenants: "auth/userTenants"
    }),
    getOneMonthAgo() {
      return moment()
        .subtract(1, "month")
        .format();
    },
    statisticsArr() {
      return Object.keys(this.statistics).map(key => {
        return {
          name: key,
          value: this.statistics[key]
        };
      });
    },
    getGmtDiff() {
      return moment().utcOffset() / 60;
    },
  },
  methods: {
    copyToClipboard,
    updateCharts(dates, prices) {
      const old_series = [...this.series];
      const old_chart_options = {...this.chartOptions};

      this.chartOptions.xaxis.categories = dates;
      this.series[0].data = prices;
      this.chartOptions = old_chart_options;
      this.series = old_series;
    },
    getColorStatus(status) {
      switch (status) {
        case "completed":
          return "mango-green-status";
        case "refunded":
          return "mango-red-status ";
        case "partially_refunded":
          return "mango-orange-status ";
        case "pending":
          return "mango-gray-status  font-weight-bold";
        default:
          return "mango-orange-status ";
      }
    },
    async initStatistics() {
      this.loading = true;

      const params = {
        fromDate: moment(this.fromDate).utc().format(),
        untilDate: moment(this.untilDate).utc().format(),
        tenantIds: this.selectedValues,
        inquiry_event: this.event.status
      };

      await this.$store.dispatch("statistics/getStatistics", params);

      const prices = this.statistics.orders.reverse().map(item => item.price.amount);
      const dates = this.statistics.orders.reverse().map(item => item.created);

      // const grouped_data = [...this.statistics.orders].reverse().reduce((acc, curr) => {
      //   const date = moment(curr.created).format("YYYY-MM-DD");
      //
      //   if (!acc[date]) {
      //     acc[date] = [];
      //   }
      //   acc[date].push(curr);
      //
      //   return acc;
      // }, {});

      // let dates = [];
      // let prices = [];

      // Object.keys(grouped_data).forEach(key => {
      //  const sum = grouped_data[key].reduce(
      //    (acc, curr) => (acc = acc + curr.calculatedPrice.total.amount),
      //    0
      //  );
      //  dates.push(key);
      //  prices.push(sum);
      //});

      this.updateCharts(dates, prices);
      this.loading = false;
    },
    async downloadAccountingReport() {
      this.loading = true;
      await this.$store.dispatch("loading", true);

      const params = {
        fromDate: moment(this.fromDate).utc().format(),
        untilDate: moment(this.untilDate).utc().format(),
        tenantIds: this.selectedValues
      };

      let data = null;
      if (this.$refs.fileUploader.uploadedFiles.length > 0) {
        data = new FormData();
        data.append('file', this.$refs.fileUploader.uploadedFiles[0]);
      }

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";

      try {
        const response = await Trainplanet.getStatisticsExcel(data, params);

        const objectUrl = URL.createObjectURL(response.data);
        a.href = objectUrl;
        a.download = response.headers['content-disposition'].split('"')[1];
        a.click();
        window.URL.revokeObjectURL(objectUrl);
      } catch (error) {
        let message;

        if (error.response) {
          message = JSON.parse(await error.response.data.text()).message;
        }

        await this.$store.dispatch("error", message || error);
      } finally {
        this.loading = false;
        await this.$store.dispatch("loading", false);
      }
    }
  }
};
</script>

<style lang="scss">
.statistic {
  &-item {
    //&:first-of-type {
    //  background: #d0edda;
    //  margin-right: 10px;
    //  color: #00591f;
    //}
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.09);
    padding: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-container {
    padding: 20px;
    // background: #fff;
    // border-radius: 10px;
    // padding: 25px;
    // box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
  }

  &-header {
    display: flex !important;
    justify-content: space-between;
  }
}

#inquiry-edit .v-tabs-bar,
#inquiry-edit .v-tabs-items {
  background-color: transparent;
  overflow: unset;
}

.my-select {
  height: 41px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
}

.my-select-dark {
  /*color: #fff;*/
  border-color: #fff;
}
</style>
