var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    class: {
      'grey lighten-2': _vm.dragover
    },
    attrs: {
      "height": "3.5em"
    },
    on: {
      "drop": function ($event) {
        $event.preventDefault();
        return _vm.onDrop($event);
      },
      "dragover": function ($event) {
        $event.preventDefault();
        _vm.dragover = true;
      },
      "dragenter": function ($event) {
        $event.preventDefault();
        _vm.dragover = true;
      },
      "dragleave": function ($event) {
        $event.preventDefault();
        _vm.dragover = false;
      }
    }
  }, [_c('input', {
    ref: "file",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "multiple": ""
    },
    on: {
      "change": _vm.onFileChange
    }
  }), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var hover = _ref.hover;
        return [_c('v-card-text', {
          staticClass: "py-0 px-0",
          class: {
            'on-hover elevation-4': hover
          },
          staticStyle: {
            "overflow": "hidden"
          },
          on: {
            "click": function ($event) {
              return _vm.$refs.file.click();
            }
          }
        }, [_vm.uploadedFiles.length === 0 ? _c('v-row', {
          staticClass: "d-flex flex-column",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', {
          class: [_vm.dragover ? 'mt-2 mb-3' : 'mt-4']
        }, [_vm._v(" mdi-cloud-upload ")]), _c('p', [_vm._v(" " + _vm._s(_vm.text) + " ")])], 1) : _vm._e(), _vm.uploadedFiles.length > 0 ? _c('v-virtual-scroll', {
          staticClass: "pt-0",
          attrs: {
            "items": _vm.uploadedFiles,
            "height": "4.3em",
            "item-height": "1px"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var item = _ref2.item;
              return [_c('v-list-item', {
                key: item.name
              }, [_c('v-list-item-content', {
                staticClass: "px-0 py-0"
              }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.name) + " "), _c('span', {
                staticClass: "ml-1 text--secondary"
              }, [_vm._v(" " + _vm._s(item.size) + " bytes")])])], 1), _c('v-list-item-action', [_c('v-btn', {
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                    return _vm.removeFile(item.name);
                  }
                }
              }, [_c('v-icon', [_vm._v(" mdi-close-circle")])], 1)], 1)], 1)];
            }
          }], null, true)
        }) : _vm._e()], 1)];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }